module.exports = [{
      plugin: require('/opt/build/repo/examples/mdx-deck/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":["gatsby-remark-import-code"],"remarkPlugins":[null,null]},
    },{
      plugin: require('/opt/build/repo/examples/mdx-deck/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/examples/mdx-deck/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/examples/mdx-deck/node_modules/gatsby-theme-mdx-deck/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
